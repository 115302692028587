.main {
  width: 100%;
  /* padding: 40px 30px; */
  background-color: #f5f6fa;
}
.main div[type="container"] {
  box-shadow: 0px 0px 4px 0px #04097B0D;
  border-radius: 10px;
  padding: 1.5rem;
  font-weight: 400;
  border: 1px solid #CDCEE5;
  background-color: #fff;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;
}
.btn {
  justify-content: space-between;
  align-items: center;
}
.btn > button {
  width: 200px;
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .main div[type="container"] {
    padding: 0.5rem;
  }
  .grid {
    grid-template-columns: 1fr;
  }
}
