.container {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(4, 9, 123, 0.05);
  border-radius: 8px;
  padding: 2rem;
  margin-top: 24px;
}

.radios {
  margin: 24px 0;
  font-weight: 300;
}

.radios div {
  margin-top: 20px;
}

.group {
  margin: 24px 0;
}

.group p {
  color: #152238;
}

.checks {
  display: flex;
  flex-wrap: wrap;
}

.checks label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 40px;
  font-weight: 300;
  color: #152238;
  margin-top: 16px;
}

.checks label input {
  margin-right: 10px;
  width: unset;
}

.btn {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn button {
  margin-left: 40px;
}

.form-group {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.form-group textarea {
  resize: none;
}

.range-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left_value,
.slider__right_value,
.minValue,
.maxValue {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  height: 20px;
  z-index: 1;
}

.slider__range {
  height: 20px;
  background-color: #f9a910;
  z-index: 2;
}

.slider__left_value,
.slider__right_value,
.minValue,
.maxValue {
  color: #000000;
  font-size: 16px;
  margin-top: 20px;
  top: 10px;
}
.minValue {
  left: 10px;
}
.maxValue {
  right: 10px;
}
.slider__left_value {
  left: 40%;
}
.slider__right_value {
  right: 45%;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 80%;
  outline: none;
}

.thumb__zindex3 {
  z-index: 3;
}

.thumb__zindex4 {
  z-index: 4;
}

.thumb__zindex5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 34px;
  width: 34px;
  margin-top: 20px;
  pointer-events: all;
  position: relative;
  z-index: 10;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 34px;
  width: 34px;
  margin-top: 20px;
  pointer-events: all;
  position: relative;
  z-index: 10;
}

.tagWrapper {
  border: 1px solid #c8c5c5;
  border-radius: 7px;
  padding: 2rem 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  justify-content: center;
}
.tag {
  padding: 0.5rem;
  border: 1px solid #030659;
  border-radius: 7px;
  font-size: 14px;
  color: #030659;
  margin: 1rem;
  text-align: center;
  transition: all 0.5s;
}
.tag:hover {
  background: #030659;
  color: #fff;
}

.avatar > * {
  width: 118px;
  height: 118px;
  border-radius: 50%;

}


.skills input {
  border: none !important;
  margin: 0 !important;
}
.skills input:focus {
  outline: none !important;
  border: none !important;
}
.skills > div {
  border: 1px solid #c8c5c5;
  border-radius: 7px;
  padding: 5px 17px 0px;
  min-height: 121px;
}
.skills > div > span > span {
  background: #fff;
  color: #030659;
  font-size: 15px;
  border: 1px solid #030659;
  border-radius: 7px;
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
  gap: 14px;
  padding-left: 10px;
  padding-right: 10px;
}

.skills > div > span {
  display: flex;
  align-items: center;
}
.skills {
  border-radius: 7px;
  margin-bottom: 16px;
}

.skills input {
  color: #030659;
}


.verify-status-section {
  margin: 80px 0 20px;
}
@media screen and (max-width: 768px) {
  .slider__left_value {
    left: 30%;
  }
  .slider__right_value {
    right: 30%;
  }
}