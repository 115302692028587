.btn {
  justify-content: space-between;
}

.btn button {
  display: flex;
  /* width: 150px; */
  height: 40px;
  border: none;
  background: none;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.active {
  background: #fee8bc !important;
  color: #eb9b01;
}

.btn input {
  background-color: transparent;
  width: unset;
  height: 40px;
}

.table {
  width: 100%;
  /* text-align: center; */
  background-color: #fff;
  padding: 2rem;
}

.header {
  display: grid;
  grid-template-columns: minmax(150px, 2fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(150px, 2fr);
  gap: 5px;
  padding: 5px 0 20px 0;
}

.grid {
  border: 1px solid #cdcee5;
  border-radius: 8px;
  display: grid;
  grid-template-columns: minmax(150px, 2fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(150px, 2fr);
  gap: 5px;
  align-items: center;
  padding: 15px 20px;
  margin: 20px 0;
  overflow-x: auto;
}

.grid button {
  background: none;
  border: none;
  margin: 0 5px;
}

.grid img {
  width: 30px;
  height: auto;
}


.table table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.table tr:hover td {
  background: #7FCB6C6E;
}

.table tr td:first-child {
  border-color: #cdcee5;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table tr td:last-child {
  border-color: #cdcee5;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table th {
  padding: 15px !important;
}

.table td {
  padding: 15px;
  background: #eeeeee7b; /* Background color to visualize the effect */
  /* border: 1px solid #ddd; */
}


.description {
  background: #030659;
  color: #fff;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  min-width: 130px;
}
.arrowdown {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 12px solid #030659;
  display: inline-block;
  position: absolute;
  bottom: -12px;
  right: 8px;
}
.hidden {
  position: absolute;
  top: -40px;
  right: -5px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.edit:hover > div,
.delete:hover > div,
.archive:hover > div {
  visibility: visible;
  opacity: 1;
}
