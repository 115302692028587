
.switch {
  display: flex;
  align-items: center;
}

.switch span {
  position: relative;
  width: 60px;
  height: 34px;
  display: inline-block;
}
.switch input {
  display: none;
}
.switch input:checked + .slider {
  background-color: #059A16;
}
.switch input:checked + .slider:before {
  transform: translateX(26px);
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #059A16;
}

.switch label {
  line-height: 34px;
  margin-right: 14px;
  cursor: pointer;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border: 0;
  outline: none;
  background-color: #ECEBEB;
  transition: 0.4s;
  border-radius: 34px;
  cursor: pointer;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.4s;
  border-radius: 50%;
}

.verified {
  display: flex;
  justify-content: end;
  color: #000000;
  font-size: 12px;
  font-weight: 300;
}