.edit {
  position: absolute;
  right: 20px;
  top: 20px;
}

.line {
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin-right: 16px;
}
.line + div {
  width: 80%;
}
.track {
  justify-content: center;
  align-items: center;
}
.company {
  margin: 10px 0;
}
.industry {
  color: #f9a910;
}

.align {
  align-items: center;
}
.briefcase {
  margin-bottom: 4rem;
}
.span {
  background-color: #000;
  width: 2px;
  height: 20px;
  display: inline-block;
  margin: 0 10px;
}

.mainContainer {
  margin-bottom: 24px;
}
