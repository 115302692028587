.container {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(4, 9, 123, 0.05);
  border-radius: 8px;
  padding: 2rem;
  margin-top: 24px;
}

.form-group {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.radios {
  margin: 24px 0;
  font-weight: 300;
}

.radios div {
  margin-top: 20px;
}

input:disabled {
  background-color: #cdcee5;
  border: 1px solid #030659;
}

.btn {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn button {
  margin-left: 40px;
}
