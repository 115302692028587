@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  height: 100%;
  font-size: 16px;
}
body {
  font-family: "Lexend", sans-serif;
  min-width: 100%;
  max-width: min(100%, 1650px);
  margin: 0 auto;
  color: #020202;
}
input,
select,
textarea {
  width: 100%;
  border: 1px solid #cdcee5;
  border-radius: 7px;
  padding: 14px;
  font-size: 16px;
  font-weight: 300 !important;
}
input:focus {
  border-color: #030659;
  outline: none;
}
input::placeholder, textarea::placeholder {
  font-weight: 300 !important;
}
a {
  text-decoration: none;
  color: inherit;
}
button {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
}
button,
label {
  cursor: pointer;
}
button:disabled {
  cursor: not-allowed;
  color: grey;
}

/* Global Table styling */
thead {
  border-bottom: 1px solid #CDCEE5;
}
thead th {
  background-color: transparent;
  padding: 10px 0 30px 0;
}
.p-dropdown-label.p-inputtext {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-tagsinput-tag {
  min-width: fit-content;
}
.tags-wrapper > span {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

/* Global quill editor styling */
.p-editor-container {
  border: 1px #CDCEE5 solid;
  border-radius: 7px;
  height: max-content;
}
.ql-toolbar {
  border: none !important;
  border-bottom: 1px #CDCEE5 solid !important;
}
.ql-container {
  border: none !important;
}
.ql-editor {
  border: none !important;
  height: 188px !important;
  max-height: 188px !important;
}