.main {
  width: 100%;
  min-height: 100vh;
  background: url("../../assets/images/backgrounds/circleBg.png") 100% center,
    linear-gradient(180deg, #090e89 -30.09%, #030659 100%);
  position: relative;
  text-align: center;
  padding: 1rem 0 4rem 0;
}

.logo > svg {
  max-width: 80%;
  height: auto;
}
.container {
  background-color: #fff;
  width: min(800px, 90%);
  margin: auto;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding-bottom: 50px;
}
.small-logo {
  padding-top: 2.5rem;
}
.small-logo img {
  max-width: 80px;
  height: auto;
  border-radius: 15px;
}
