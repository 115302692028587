.container {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(4, 9, 123, 0.05);
  border-radius: 8px;
  padding: 2rem;
  margin-top: 24px;
}

.radios {
  margin: 24px 0;
  font-weight: 300;
}

.radios div {
  margin-top: 20px;
}

.group {
  margin: 24px 0;
}

.group p {
  color: #152238;
}

.checks {
  display: flex;
  flex-wrap: wrap;
}

.checks label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 40px;
  font-weight: 300;
  color: #152238;
  margin-top: 16px;
}

.checks label input {
  margin-right: 10px;
  width: unset;
}

.btn {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn button {
  margin-left: 40px;
}

.form-group {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}