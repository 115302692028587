.main {
  background: #fff;
  width: 60%;
  margin: auto;
  min-height: 60vh;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 4rem;
}
.main svg {
  max-width: 90%;
  height: auto;
}
.main h2 {
  color: #030659;
  padding: 0 20px;
}
.main p {
  color: #000a0b;
  padding: 0 40px;
}
.main button {
  padding: 1rem;
  font-size: 16px;
  width: 150px;
}
@media screen and (max-width: 768px) {
  .main svg {
    max-width: 60%;
    height: auto;
  }
  .main {
    width: 90%;
    padding: 1rem;
  }
}
