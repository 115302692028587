.edit {
  position: absolute;
  right: 20px;
  top: 20px;
}
.span {
  background-color: #000;
  width: 2px;
  height: 20px;
  display: inline-block;
  margin: 0 10px;
}
.industry {
  color: #f9a910;
}
.align {
  align-items: center;
}
.line {
  background-color: #c8c5c5;
  height: 1px;
  width: 100%;
  display: inline-block;
  border: 1px solid #c8c5c5;
}

.topTitle {
  margin-top: 24px;
}