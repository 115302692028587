.container {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(4, 9, 123, 0.05);
  border-radius: 8px;
  padding: 2rem;
  margin-top: 24px;
}

.radios {
  margin: 24px 0;
  font-weight: 300;
}

.radios div {
  margin-top: 20px;
}

.group {
  margin: 24px 0;
}

.group p {
  color: #152238;
}

.checks {
  display: flex;
  flex-wrap: wrap;
}

.checks label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 40px;
  font-weight: 300;
  color: #152238;
  margin-top: 16px;
}

.checks label input {
  margin-right: 10px;
  width: unset;
}

.btn {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn button {
  margin-left: 40px;
}

.form-group {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.form-group textarea {
  resize: none;
}

.avatar > * {
  width: 118px;
  height: 118px;
  border-radius: 50%;

}


/* Range */

.rating {
  z-index: 10;
  position: absolute;
  right: 0px;
  top: -32px;
  font-size: 14px;
}
.rangeParent {
  width: 100%;
}
.rangeParent span,
.rangeParent input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25px;
  border-radius: 30px !important;
}
.rangeParent aside {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #d3d3d3;
  position: absolute;
  top: 3px;
  z-index: 10;
}
.dot1 {
  right: 87.5%;
}
.dot2 {
  right: 75%;
}
.dot3 {
  right: 62.5%;
}
.dot4 {
  right: 50%;
}
.dot5 {
  right: 37.5%;
}
.dot6 {
  right: 25%;
}
.dot7 {
  right: 12.5%;
}
.dot8 {
  right: 0%;
}
.track {
  display: block;
  z-index: 50;
}
.yellow {
  background: #f9a910;
}
.blue {
  background: #030659;
}
.slider {
  width: 100%;
  background: #e0e0e0;
  outline: none !important;
  padding: 0 !important;
  margin: 0 !important;
}
.slider,
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.slider:focus {
  border: none !important;
}
/* For Chrome browsers */
.slider::-webkit-slider-thumb {
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 25px;
  width: 25px;
  pointer-events: all;
  position: relative;
  z-index: 100;
  opacity:0;
}

/* For Firefox browsers */
.slider::-moz-range-thumb {
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 25px;
  width: 25px;
  pointer-events: all;
  position: relative;
  z-index: 10;
  opacity:0;
}

.skills input {
  border: none !important;
  margin: 0 !important;
}
.skills input:focus {
  outline: none !important;
  border: none !important;
}
.skills > div {
  border: 1px solid #c8c5c5;
  border-radius: 7px;
  padding: 5px 17px 0px;
}
.skills > div > span > span {
  background: #fff;
  color: #030659;
  font-size: 15px;
  border: 1px solid #030659;
  border-radius: 7px;
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
  gap: 14px;
  padding-left: 10px;
  padding-right: 10px;
}

.skills > div > span {
  display: flex;
  align-items: center;
}
.skills {
  border-radius: 7px;
  margin-bottom: 16px;
}

.skills input {
  color: #030659;
}
