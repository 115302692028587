.edit {
  position: absolute;
  right: 20px;
  top: 20px;
}
.flex {
  justify-content: space-between;
}
.flex span:last-of-type {
  text-align: right;
}
.red {
  color: #eb6c63;
}
.yellow {
  color: #eab221;
}
.green {
  color: #08a47d;
}
.mainContainer {
  margin: 24px 0;
}