.tag {
  display: inline-block;
  padding: 0.5rem;
  border: 1px solid #030659;
  border-radius: 7px;
  color: #ffffff;
  margin: 0.5rem;
  background: #030659;
  color: #fff;
}
.edit {
  position: absolute;
  right: 20px;
  top: 20px;    
}
