.clicks span {
	margin: 0 20px;
	display: inline-block;
	padding: 20px;
	width: 178px;
	border-radius: 4px;
	cursor: pointer;
}
.active {
	background: #fee8bc;
	color: #e19606;
}

.container ul {
	list-style-type: none;
	align-items: center;
	gap: 20px;
}
.container ul li {
	padding: 10px 15px;
	cursor: pointer;
	display: flex;
	align-items: center;
}
.active {
	background: #fee8bc;
	border-radius: 4px;
	color: #e19606;
}
.container ul li span {
	padding-right: 6px;
}
.active svg path {
	fill: #e19606;
}
.inactive svg path {
	fill: #000000;
}

.card {
	background-color: #fff;
	border-radius: 8px;
	padding: 8px 16px;
	box-shadow: 0px 0px 2px 0px #00000021;
	overflow: scroll;
}


.header {
	display: flex;
	justify-content: space-between;
	gap: 16px;
}

h1 {
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 32px;
}
@media screen and (max-width: 767px) {
	.header {
		flex-direction: column-reverse;
	}
	h1 {
		display: block;
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 24px;
	}
  }