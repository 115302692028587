.login form {
  width: min(480px, 90%);
  display: block;
  margin: auto;
}

.login label {
  display: block;
  text-align: left;
  font-weight: 400;
}

.login input {
  margin-top: 12px;
}

.forgot {
  text-align: left;
  display: block;
}

.login {
  margin-bottom: 40px;
}