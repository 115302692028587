.edit {
  position: absolute;
  right: 20px;
  top: 20px;
}
.main {
  padding-top: 3rem;
  justify-content: space-between;
}
.main span {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.main span svg {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.imgDiv {
  align-items: flex-start;
}
.img {
  margin-right: 20px;
  width: 120px;
  height: 120px;
  background: #F5F6FA;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 700;
}
.img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.border {
  background: #d3d3d3;
  /* transform: rotate(90deg); */
  width: 1px;
}
.summary {
  width: 100%;
  min-height: 5rem;
  background: #f5f6fa;
  border-radius: 7px;
  display: block;
  padding: 0.5rem;
}

.jobStyle {
  font-size: 18px;
  font-weight: 400;
}

.colTwo {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
@media screen and (max-width: 1058px) {
  .main {
    flex-direction: column;
  }
  .img {
    height: 120px;
  }
}
