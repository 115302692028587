.main {
  transition: 0.2s ease-in-out;
  border-right: 1px solid #ececec;
  width: 5rem;
  overflow-y: auto;
  max-height: calc(100vh - 55px);
  z-index: 10;
}
.sidearrow {
  position: absolute;
  top: 20px;
  right: -12px;
  z-index: 1;
  padding: 4px;
  cursor: pointer;
  background: linear-gradient(207deg, #030659 -140.96%, #fff 103.18%);
  border-radius: 50%;
  transition: transform 1s ease-in-out;
}
.rotate {
  transform: rotate(180deg);
}
.main ul {
  list-style-type: none;
}
.main svg {
  max-width: 80px;
  height: auto;
}
.main button {
  border: none;
  background: none;
}
.main a,
.main button {
  margin: 10px 0px;
  display: flex;
  align-items: center;
  color: #000000;
  width: 14rem;
}
.main a span,
.main button span,
.users p,
.users a {
  visibility: hidden;
  opacity: 0;
}
.wide {
  width: 15rem;
}

.wide a span,
.wide button span,
.wide .users p,
.wide .users a {
  visibility: visible;
  opacity: 1;
}

.main a,
.main button {
  padding: 10px 0 10px 20px;
  border-radius: 0px 15px 15px 0px;
}
.main a svg,
.main button svg {
  margin-right: 20px;
}
.main li a:hover,
.main li button:hover {
  background: #04097b;
  color: #fff;
}
.stroke a:hover svg path,
.stroke button:hover svg path {
  stroke: #ffffff;
}
.fill a:hover svg path,
.fill button:hover svg path {
  fill: #fff;
}
.users {
  margin: 20px 0px;
}
.users div > svg {
  margin-right: 20px;
}
.users a {
  margin-left: 20px;
  width: 10rem;
}
.users > div:first-of-type {
  display: flex;
  align-items: center;
  color: #000000;
  width: 14rem;
}
.users > div:last-of-type {
  width: 14rem;
  border-radius: 0 0 15px 0;
}
.users div {
  padding: 10px 0 10px 20px;
}
.activeLink > div:first-of-type {
  border-radius: 0 15px 0 0;
}
.activeLink div {
  background-color: #04097b;
  border-radius: 0px 15px 15px 0px;
  padding: 10px 0 10px 20px;
}
.activeLink a,
.activeLink p {
  color: #fff;
}
.activeLink svg path {
  fill: #fff;
}
.activeLink .talents,
.activeLink .clients {
  background-color: #fff;
  color: #04097b !important;
}
.activeLink a:hover {
  background-color: #fff !important;
  color: #04097b !important;
}
.activeLink .talents:hover,
.activeLink .clients:hover {
  background-color: #fff;
  color: #04097b !important;
}
.up {
  transform: rotate(90deg);
}
.down {
  transform: rotate(270deg);
}
@media (max-width: 768px) {
  .sidearrow {
    display: none !important;
  }
  .main {
    position: fixed;
    left: -100vw;
    transition: 0.5s ease-in-out;
  }
  .wide {
    top: 50px;
    left: 0;
    width: 100%;
    overflow-y: scroll;
    background-color: #fff;
  }
}
