.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 1fr));
  gap: 30px;
}
.img {
  background-color: #fff;
  border-radius: 50%;
}
.img img {
  padding: 20px;
}
.summary {
  justify-content: space-between;
  border-radius: 8px;
  align-items: center;
  padding: 20px;
}
.summary hr {
  margin: 24px 0;
  width: 100%;
}
.summary > div:first-of-type {
  width: 60%;
}
.blue {
  background: #030659;
  box-shadow: 0px 0px 4px rgba(4, 9, 123, 0.05);
  color: #fff;
}

.grey {
  background: #ececec;
  box-shadow: 0px 0px 4px rgba(4, 9, 123, 0.05);
}

.white {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(4, 9, 123, 0.05);
}
.yellow {
  background: #fbe6bb;
  box-shadow: 0px 0px 4px rgba(4, 9, 123, 0.05);
}
.notification {
  background-color: #fff;
  padding: 2rem;
}
.btn button {
  background: #fff;
  border: none;
  margin: 0 10px;
  padding: 20px;
  border-radius: 5px;
}
.active {
  background: #fee8bc !important;
  color: #eb9b01 !important;
}
.activity {
  justify-content: space-between;
  padding: 15px;
}
.activity:nth-of-type(even) {
  background-color: #fff;
}
.activity:nth-of-type(odd) {
  background-color: #f5f6fa;
}
.link {
  display: block;
  text-align: right;
  margin-top: 20px;
}
