.edit {
  position: absolute;
  right: 20px;
  top: 20px;
}
.main {
  align-items: center;
}

.download {
  width: 140px;
  align-items: center;
  border: 1px solid #030659;
  border-radius: 7px;
  background: transparent;
  color: #030659;
  margin-right: 20px;
}
.download img {
  margin: 10px;
}
