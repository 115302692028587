.checkbox {
  padding: 0px 0 25px 25px;
  position: relative;
}
.checkbox input {
  width: 0;
  height: 0;
  opacity: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #000000;
  background-color: transparent;
  border-radius: 4px; /* Square shape with slightly rounded corners */
}
.checkbox input:checked ~ .checkmark {
  border: 2px solid #f9a910;
  background-color: transparent;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark (✓) when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox input:checked ~ .checkmark:after {
  position: absolute;
  top: 1px;
  left: 5px;
  width: 6px;
  height: 12px;
  border: solid #f9a910;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox.indeterminate input:checked ~ .checkmark:after {
  display: block;
  position: absolute;
  content: "";
  top: 7px;
  left: 3px;
  width: 10px;
  height: 2px;
  background-color: #f9a910 !important;
  transform: rotate(0deg);
}