.parent {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	align-items: center;
	border: 1px solid #e0e0e0;
	padding: 1rem;
	border-radius: 10px;
	gap: 8px;
}
.subtitle {
	padding-block: 0.5rem;
	font-weight: 100;
}
.imgDiv {
	width: 50px;
	height: 50px;
}
.imgDiv img {
	width: 100%;
	height: 100%;
}

/* .address {
	width: 140px;
} */
.svgs {
	flex-direction: column;
}
.svgs svg {
	margin: 5px 0;
}
.categoryFilters {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	align-items: center;
	border-bottom: 1px solid #e0e0e0;
	padding: 1rem 2rem;
	gap: 8px;
	min-width: 1200px;
}

.sort {
	display: flex;
	align-items: center;
	gap: 0.5rem;

	cursor: pointer;
}

.status {
	display: flex;
	gap: 1rem;
	align-items: center;
	z-index: 5;

	& > span {
		border-radius: 50%;
		width: 2rem;
		aspect-ratio: 1;
		color: white;

		display: grid;
		place-items: center;
	}
}

.check {
	background-color: #219653;
}

.times {
	background-color: #fb1a1a;
	font-size: 1.5em;
}

.parent h3 {
	font-size: 14px;
	font-weight: 500;
}
.parent h4, .parent p {
	font-size: 14px;
	font-weight: 300;
}

.scroll {
	height: 65vh;
	min-width: 1200px;
	overflow: scroll;
	padding:0 16px;
}

.actionGroup {
	display: flex;
	align-items: center;
	gap: 8px;
}

@media screen and (max-width: 767px) {
	.categoryFilters {
		padding-left: 0;
		padding-right: 0;
	}

	.scroll {
		padding: 0;
	}

}