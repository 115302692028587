.grid {
  display: grid;
  grid-template-columns: repeat(6, 50px);
  grid-gap: 20px;
  justify-content: center;
}
.grid input {
  width: 50px;
  height: 50px;
  border: 1px solid #c8c5c5;
  border-radius: 7px;
  text-align: center;
}
.grid input:focus {
  border: 1px solid #000000;
  outline: 1px solid #000000;
}
.btn {
  justify-content: center;
  align-items: center;
}
.btn button {
  width: 200px;
  margin: 0 20px;
}
@media screen and (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(6, 35px);
    grid-gap: 10px;
  }
  .grid input {
    width: 35px;
    height: 35px;
  }
}
